import React, { useState } from "react"
import StoryblokClient from "storyblok-js-client"
import Layout from "../components/structural/layout"

import SectionNewsPostHero from "../components/sections/sectionNewsPostHero"
import ShareLinks from "../components/global/shareLinks"
import OTLink from "../components/global/otLink"
import { format } from "date-fns"
import SmartText from "../utils/TextHandler"

import { storyblokEditable, StoryblokComponent } from "gatsby-source-storyblok"

const SBEditor = () => {
  const [storyblokData, setStoryblokData] = useState(null)
  const [templateData, setTemplateData] = useState("loading")

  function loadBridge(callback) {
    if (typeof window !== "undefined") {
      const existingScript = document.getElementById("storyblokBridge")
      if (!existingScript) {
        const script = document.createElement("script")
        script.src = "//app.storyblok.com/f/storyblok-v2-latest.js"
        script.id = "storyblokBridge"
        document.body.appendChild(script)
        script.onload = () => {
          callback()
        }
      } else {
        callback()
      }
    }
  }

  loadBridge(() => {
    if (typeof window !== "undefined") {
      const { StoryblokBridge, location } = window

      const storyblokInstance = new StoryblokBridge({
        preventClicks: true,
        resolveNestedRelations: true,
        resolveLinks: ["url", "story", "link"],
      })

      const Storyblok = new StoryblokClient({
        accessToken: process.env.GATSBY_SB_PREVIEW_API_KEY,
        cache: {
          clear: "auto",
          type: "memory",
        },
        resolveNestedRelations: true,
        resolveLinks: ["url", "story", "link"],
      })

      storyblokInstance.pingEditor(() => {
        if (storyblokInstance.isInEditor()) {
          //  load the draft version
          //        alert("In the editor")
        } else {
          // load the published version
        }
      })

      storyblokInstance.on("enterEditmode", event => {
        // Read the URL of the loaded page to get the template name
        const urlParams = new URLSearchParams(window.location.search)
        const myParam = urlParams.get("_storyblok_c")

        setTemplateData(myParam)

        // loading the draft version on initial enter of editor
        Storyblok.get(`cdn/stories/${event.storyId}`, {
          token: process.env.GATSBY_SB_PREVIEW_API_KEY,
          version: "draft",
          resolve_assets: "1",
          resolve_links: "url,story,link",
        })
          .then(({ data }) => {
            // console.log(data)
            //          if (typeof data.story.content === "string")
            //            data.story.content = JSON.parse(data.story.content)
            setStoryblokData(data.story)
          })
          .catch(error => {
            console.log(error)
          })
      })

      storyblokInstance.on("input", event => {
        // Access currently changed but not yet saved content via:
        //   console.log(event.story.content)
        setStoryblokData(event.story)

        //    alert(JSON.stringify(event.story.content))
      })

      storyblokInstance.on(["published", "change"], event => {
        if (!event.slugChanged) {
          location.reload(true)
        }
      })
    }
  })

  if (storyblokData === null) return <p className="mt-16">Loading...</p>

  console.log("storyblokData", storyblokData)

  if (templateData === "newspost") {
    const { content } = storyblokData

    if (!content) return null

    // If we end up adding more page types extract this to a separate component to keep bloat down in this file
    return (
      <Layout isEditorMode={true}>
        <div
          {...storyblokEditable(content)}
          className="container mx-auto pt-16"
        >
          <SectionNewsPostHero
            blok={{
              background_image: content?.image,
            }}
          />

          <section className="section-container pt-12">
            <h1 className="font-bold pb-6">{content?.title}</h1>
            <div className="flex flex-col justify-start items-between md:flex-row md:justify-between md:items-center gap-y-6">
              <p className="font-bold desktop-small">
                Posted on{" "}
                {format(new Date(storyblokData.published_at), "dd MMMM yyyy")}
              </p>
              <ShareLinks
                className="flex flex-row justify-start items-center gap-x-4"
                meta={{
                  title: content?.seo?.title,
                  description: content?.seo?.description,
                  image: content?.seo_image?.filename,
                }}
                openGraph={{
                  type: "website",
                  title: content?.seo?.title,
                  description: content?.seo?.description,
                  image: content?.seo_image?.filename,
                }}
                facebook
                twitter
                linkedin
              />
            </div>
          </section>

          <section className="section-container pt-12">
            <SmartText>{content?.text_content}</SmartText>
          </section>

          <section className="section-container text-center py-12">
            <OTLink
              className="text-button"
              icon="fa-chevron-left"
              iconPosition="start"
              to="/news"
            >
              BACK TO NEWS
            </OTLink>
          </section>
        </div>
      </Layout>
    )
  }

  const { content } = storyblokData

  const components = content?.content?.map(blok => (
    <StoryblokComponent blok={blok} doc={blok} key={blok._uid} />
  ))

  const shouldAddFirstComponentStyle = [
    "careers",
    "default",
    "games",
    "game",
    "legal",
    "news",
  ].find(item => item === templateData)

  const firstComponent =
    content?.content?.length > 0 ? content?.content[0]?.component : "unknown"

  return (
    <div {...storyblokEditable(content)} className="editor-only-styles">
      <Layout isEditorMode={true}>
        <div
          className={`mx-auto ${
            !!shouldAddFirstComponentStyle
              ? firstComponent !== "section_hero" && `mt-20`
              : "container py-16"
          }`}
        >
          {components}
        </div>
      </Layout>
    </div>
  )
}

export default SBEditor
